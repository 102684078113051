<!-- @format -->
<template>
  <b-card title="文章添加">
    <validation-observer ref="createProdBrand">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="文章标题" label-cols="1" label-cols-md="3" content-cols="6"
                          content-cols-md="8"
            >
              <validation-provider #default="{ errors }" name="文章标题" rules="required">
                <b-form-input v-model="formData.title" :state="errors.length > 0 ? false : null"
                              placeholder="请输入文章标题"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="封面图" label-cols="1" label-cols-md="3" content-cols="6"
                          content-cols-md="8"
            >
              <input type="file" placeholder="上传封面图" ref="getImage" @change="changeInput">
              <img :src="formData.cover" alt="加载失败"
                   style="width: 60px; height: 60px; border-radius: 50%; box-shadow: 0 0 5px #ccc; display: inline-block"
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="文章简介" label-cols="1" label-cols-md="3" content-cols="6"
                          content-cols-md="8"
            >
              <validation-provider #default="{ errors }" name="文章简介" rules="required">
                <b-form-textarea v-model="formData.description" :state="errors.length > 0 ? false : null"
                                 placeholder="请输入文章简介"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="文章分类" label-cols="1" label-cols-md="3" content-cols="6"
                          content-cols-md="8"
            >
              <validation-provider #default="{ errors }" name="文章分类" rules="required">
                <vSelect
                    :reduce="item => item.id"
                    label="title"
                    :options="articleCate"
                    @search="searchCate"
                    v-model="formData.cate_id"
                    placeholder="请选择文章分类"
                    :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"
                    :state="errors.length > 0 ? false : null"
                >
                  <li slot="list-footer" class="pagination_vselest">
                    <b-button :disabled="!hasPrevpage" @click.prevent="changeCatePage('prev')">上一页</b-button>
                    <b-button :disabled="!hasNextpage" @click.prevent="changeCatePage('next')">下一页</b-button>
                  </li>
                </vSelect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="formData.cate_id==3">
          <b-col md="12">
            <b-form-group label-align="center" label="链接地址" label-cols="1" label-cols-md="1" content-cols="9"
                          content-cols-md="9"
            >
              <b-form-input v-model="formData.url" placeholder="请输入链接地址"/>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <b-row v-if="formData.cate_id==2">
          <b-col md="12">
            <b-form-group label-align="center" label="链接地址" label-cols="1" label-cols-md="1" content-cols="9" content-cols-md="9">
              <b-form-input v-model="formData.url" placeholder="请输入链接地址" />
            </b-form-group>
          </b-col>
        </b-row> -->
        <b-row v-if="formData.cate_id != 3">
          <b-col md="12">
            <b-form-group label-align="center" label="文章内容" label-cols="1" label-cols-md="1" content-cols="9"
                          content-cols-md="9"
            >
              <validation-provider #default="{ errors }" name="文章内容" rules="required">
                <WangEditor v-model="formData.content"/>
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="文章状态" label-cols="1" label-cols-md="3" content-cols="6"
                          content-cols-md="8"
            >
              <b-form-radio-group
                  v-model="formData.status"
                  :options="[
                  {
                    text: '启用',
                    value: 1,
                  },
                  {
                    text: '禁用',
                    value: 0,
                  },
                ]"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm" :disabled="isRequest"> 提交
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { BCard, BFormTextarea } from 'bootstrap-vue'
import { reactive, ref, onUnmounted, getCurrentInstance, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import vSelect from 'vue-select'
import { required, url } from '@core/utils/validations/validations'
import WangEditor from '@/views/GeneralManagement/WebManager/Article/WangEditor.vue'

import store from '@/store'
import axiosIns from '@/libs/axios'
import ClassManagerModule from './AticleModule'
import axios from '@/libs/axios'
import { useToast } from 'vue-toastification/composition'

export default {
  name: 'BrandCreate',
  components: {
    BCard,
    vSelect,
    WangEditor,
    BFormTextarea
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'article-module'
    const { router } = useRouter()
    const { proxy } = getCurrentInstance()
    const toast = useToast()
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, ClassManagerModule)
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
    const isRequest = ref(false)
    const formData = reactive({
      status: 1,
      cover: '',
    })
    const createProdBrand = ref(null)

    const validationForm = () => {
      if (!formData.cover) {
        proxy.$bvToast.toast('请上传封面图', {
          title: '提示',
          solid: true,
        })
        return false
      }
      isRequest.value = true
      createProdBrand.value
          .validate()
          .then(res => {
            if (res) {
              store.dispatch('article-module/createArticle', { ...formData })
                  .then(response => {
                    if (response.code === 0) {
                      proxy.$bvToast.toast('添加成功', {
                        title: '提示',
                        variant: 'success',
                        solid: true,
                      })
                      setTimeout(() => {
                        isRequest.value = false
                        router.back()
                      }, 2000)
                    } else {
                      proxy.$bvToast.toast(response.msg, {
                        title: '提示',
                        variant: 'success',
                        solid: true,
                      })
                    }
                  })
            } else {
              isRequest.value = false
            }
          })
          .catch(() => {
            isRequest.value = false
            console.log('validationForm error')
          })
    }

    // 获取文章分类
    const articleCate = ref([])

    const catePage = ref(1)
    const catePageSize = ref(10)
    const cateTotal = ref(0)
    const hasPrevpage = computed(() => catePage.value > 1)
    const hasNextpage = computed(() => catePage.value * catePageSize.value < cateTotal.value)
    axiosIns.get('/article/getArticleCateList', { params: { pageSize: 10 } })
        .then(response => {
          const {
            list,
            total
          } = response.data
          articleCate.value = list
          cateTotal.value = total
        })
        const searchCate = (search = '', loading) => {
  if (loading) loading(true)
  axiosIns.get('/article/getArticleCateList', {
    params: {
      pageSize: 10,
      page: catePage.value,
      title: search
    }
  })
  .then(response => {
    const {
      list,
      total
    } = response.data
    articleCate.value = list
    cateTotal.value = total
    if (loading) loading(false)
  })
}

const changeCatePage = (action) => {
  if (action === 'next' && hasNextpage.value) {
    catePage.value++
  } else if (action === 'prev' && hasPrevpage.value) {
    catePage.value--
  }
  searchCate()
}

    const previmageRUL = ref('')
    const changeInput = (val = {}) => {
      const form = new FormData()
      form.append('image', val.target.files[0])
      // eslint-disable-next-line no-multi-assign
      previmageRUL.value = window.URL.createObjectURL(val.target.files[0])
      axios.post('/upload/uploadAvatar', form)
          .then(response => {
            if (response.code === -1) {
              toast.error(response.msg)
            } else {
              formData.cover = process.env.VUE_APP_BASE_URL.replace('/backend', '/') + response.data.url
              console.log(formData.cover)
            }
          })
          .catch(res => {
            toast.error(res.msg)
          })
    }


    return {
      validationForm,

      formData,
      createProdBrand,

      isRequest,
      required,
      url,
      catePage,
      articleCate,
      hasPrevpage,
      hasNextpage,
      searchCate,
      previmageRUL,
      changeInput,
      changeCatePage
    }
  },
}
</script>

<style scoped>
.style-chooser .vs__search::placeholder {
  color: #ccc;
}

.style-chooser .vs__dropdown-toggle {
  border: 1px solid red;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}
</style>
