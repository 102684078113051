var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"文章添加"}},[_c('validation-observer',{ref:"createProdBrand"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-align":"center","label":"文章标题","label-cols":"1","label-cols-md":"3","content-cols":"6","content-cols-md":"8"}},[_c('validation-provider',{attrs:{"name":"文章标题","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"请输入文章标题"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-align":"center","label":"封面图","label-cols":"1","label-cols-md":"3","content-cols":"6","content-cols-md":"8"}},[_c('input',{ref:"getImage",attrs:{"type":"file","placeholder":"上传封面图"},on:{"change":_vm.changeInput}}),_c('img',{staticStyle:{"width":"60px","height":"60px","border-radius":"50%","box-shadow":"0 0 5px #ccc","display":"inline-block"},attrs:{"src":_vm.formData.cover,"alt":"加载失败"}})])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-align":"center","label":"文章简介","label-cols":"1","label-cols-md":"3","content-cols":"6","content-cols-md":"8"}},[_c('validation-provider',{attrs:{"name":"文章简介","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"请输入文章简介"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-align":"center","label":"文章分类","label-cols":"1","label-cols-md":"3","content-cols":"6","content-cols-md":"8"}},[_c('validation-provider',{attrs:{"name":"文章分类","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vSelect',{class:[errors.length > 0 ? 'style-chooser' : 'style-chooser-none'],attrs:{"reduce":function (item) { return item.id; },"label":"title","options":_vm.articleCate,"placeholder":"请选择文章分类","state":errors.length > 0 ? false : null},on:{"search":_vm.searchCate},model:{value:(_vm.formData.cate_id),callback:function ($$v) {_vm.$set(_vm.formData, "cate_id", $$v)},expression:"formData.cate_id"}},[_c('li',{staticClass:"pagination_vselest",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('b-button',{attrs:{"disabled":!_vm.hasPrevpage},on:{"click":function($event){$event.preventDefault();return _vm.changeCatePage('prev')}}},[_vm._v("上一页")]),_c('b-button',{attrs:{"disabled":!_vm.hasNextpage},on:{"click":function($event){$event.preventDefault();return _vm.changeCatePage('next')}}},[_vm._v("下一页")])],1)]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.formData.cate_id==3)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-align":"center","label":"链接地址","label-cols":"1","label-cols-md":"1","content-cols":"9","content-cols-md":"9"}},[_c('b-form-input',{attrs:{"placeholder":"请输入链接地址"},model:{value:(_vm.formData.url),callback:function ($$v) {_vm.$set(_vm.formData, "url", $$v)},expression:"formData.url"}})],1)],1)],1):_vm._e(),(_vm.formData.cate_id != 3)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-align":"center","label":"文章内容","label-cols":"1","label-cols-md":"1","content-cols":"9","content-cols-md":"9"}},[_c('validation-provider',{attrs:{"name":"文章内容","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('WangEditor',{model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}})]}}],null,false,3342295235)})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-align":"center","label":"文章状态","label-cols":"1","label-cols-md":"3","content-cols":"6","content-cols-md":"8"}},[_c('b-form-radio-group',{attrs:{"options":[
                {
                  text: '启用',
                  value: 1,
                },
                {
                  text: '禁用',
                  value: 0,
                } ]},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.isRequest},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" 提交 ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }